// 手机号登录
export const login 				= (params) => window._http('/Auth-login', params)

// 退出登录
export const logout 			= (params) => window._http('/Auth-logout', params)

// 找回密码
export const findPwd 			= (params) => window._http('/Auth-forget', params)

// 注册新用户
export const register 		= (params) => window._http('/Auth-register', params)

// 发送短信验证码
export const sendCaptcha 	= (params) => window._http('/Sms-verificationCode', params)

// 微信登录
export const wechatLogin 	= (params) => window._http('/Auth-wxlogin', params)

// 主动刷新token
export const refreshToken = (params) => window._http('/Auth-refresh', params)
